import React from "react"
import { graphql, Link } from "gatsby"

import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import FeatureSide from '../../../components/feature-side'
import FeatureRelated from '../../../components/feature-related'

const Home = ({ data }) => {
  return (
    <Layout>
      <SEO
        pagetitle="「君の心の中にある英雄を捨ててはいけないよ」。チャレンジする勇気をくれる名言！"
        pagepath="/feature/11/"
      />
      <main>
        <div className="l-feature__mv">
          <div className="l-feature__bg">
            <StaticImage
							src="../../../assets/images/feature/challenge01.jpg"
							alt="「君の心の中にある英雄を捨ててはいけないよ。」。チャレンジする勇気をくれる名言！"
						/>
          </div>
          <div className="l-feature__above">
            <h3 className="l-feature__hdg">「君の心の中にある英雄を捨ててはいけないよ」。<br />チャレンジする勇気をくれる名言！</h3>
          </div>
        </div>
        <div className="l-feature">
          <div className="l-wrap">
            <div className="l-feature__item">
              <div className="l-feature__box">

                <div className="l-feature__main">

                  <div className="l-feature__trunk">
                    <div className="l-feature__paragraph">
                      <h2 className="l-feature__lead">
                        <span>「どうせ私にはできない」「やるのが遅すぎた」なんて言い訳をするのは、カンタン。</span>
                        <span>今日が人生で一番若い日。</span>
                        <span>
                          悩みと杞憂の乗り越えて、本当にやりたかったことにチャレンジする勇気を！
                        </span>
                      </h2>
                    </div>

                    <div className="l-feature__paragraph">
                      <div className="l-feature__wrap">
                        <div className="l-feature__num">01.</div>
                        <div className="l-feature__quote">
                          {data.post1.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>ジョージエリオットは、イギリスの作家。</p>
                        <p>同棲愛者の彼は、その生涯の中で2人の男性と付き合い、結婚しました。</p>
                        <p>当時は現代に比べて、<span className="marker-b">同姓愛の理解が社会的に進んでいなかった</span>ことがあると思います。</p>
                        <p>状況下であっても、<span className="f-l">自分の感性に素直に従ったジョージ。</span></p>
                        <p>そんな彼の言葉だからこそ、重みが感じられます。</p>
                        <p>あなたに心の底からやりたいことがあるなら<span className="f-l">今すぐやりましょう。</span></p>

                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">02.</div>
                        <div className="l-feature__quote">
                          {data.post2.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>私たちは<span className="marker-b">教育</span>によって形作られています。</p>
                        <p>それは学校教育だけではありません。</p>
                        <p><span className="f-l">親の言葉、学校での友達の行動、テレビやラジオから流れてくる会話。</span></p>
                        <p>様々な外的要因から私たちは<span className="f-l">生き方やモラル</span>を学んでいきます。</p>
                        <p>それらの道徳規範は全て正しいのでしょうか？</p>
                        <p>親の言葉や、学校の先生の言葉を信じていれば必ず輝かしい未来が保障されているのでしょうか？</p>
                        <p>そんな事はありません。</p>
                        <p>人生とは、<span className="marker-b">自分が大切にする行動規範を見つける作業</span>と言ってもいいかもしれません。</p>
                        <p>その時に必要になるのは、ガンディーが言う、<span className="f-l">あなたの心に響く小さな声</span>だと思います。</p>
                        <p>正しい生き方をしてください。正しい知識を学びましょう。そして心の中の小さな声に耳を傾けましょう！</p>

                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">03.</div>
                        <div className="l-feature__quote">
                          {data.post3.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>日本が世界に誇る企業、本田技研工業。</p>
                        <p>創業した本田宗一郎は伝説の経営者として有名ですね。</p>
                        <p>宗一郎は16歳の時に、自動車修理工場「アート商会」に丁稚奉公として入社します。</p>
                        <p>のれん分けが許されるまでの6年間、宗一郎はアート商会で技術を磨きます。</p>
                        <p>そして独立後、あの有名な本田技研工業の大躍進が始まるのです。</p>
                        <p><span className="marker-b">総一郎にとってのアート商会での6年間、これが後の成功の礎になったはずです。</span></p>
                        <p><span className="f-l">誰しもに必要な下積み期間。</span>始めるなら今です。きっと宗一郎も同じことを言うでしょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">04.</div>
                        <div className="l-feature__quote">
                          {data.post4.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>劇作家で、演劇実験室「天井桟敷」の主宰であった寺山修司。</p>
                        <p>彼が書いた『書を捨てよ町へ出よう』は時代を超えて愛される名著です。</p>
                        <p>寺山は、<span className="f-l">多作で知られ、多数の文芸作品</span>を世に送り出しました。</p>
                        <p>そんな活動に生きたアーティストである寺山が語るこの言葉は、アーティストの本質である「作り続けること」そして輝かしい未来のみにフォーカスする大切さと勇気を与えてくれます。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">05.</div>
                        <div className="l-feature__quote">
                          {data.post5.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>ニーチェの有名な言葉。</p>
                        <p><span className="marker-b">後悔は決して悪いものではありません。</span></p>
                        <p>後悔から学び、同じ轍を踏まない限り、<span className="f-l">後悔は経験すべきもの</span>だったと言えるからです。</p>
                        <p>しかし愚かなわたしたちは、<span className="marker-b">くよくよと後悔をこねくり回し貴重な時間を無駄に過ごして</span>しまいます。</p>
                        <p>その愚かな行動は、現代においても、ニーチェの生きた時代においても同じだったのでしょう。</p>
                        <p>この言葉は、そのような私たちの弱さを喝破してくれる力を持っています。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">06.</div>
                        <div className="l-feature__quote">
                          {data.post6.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>歴史に残る名著、「夜と霧」の作者、ヴィクトール・フランクル。</p>
                        <p>ナチスドイツによるユダヤ人収容施設での壮絶な体験を元に、<span className="marker-b">r人間が如何にして生きるのか</span>を解き明かしました。</p>
                        <p>そんなフランクルの言葉は、限りない説得力を持って私たちに訴えかけてきます。</p>
                        <p>どのような絶望的な状況にあっても、私たちは常に<span className="f-l">「どう行動するか」</span>という意志があります。</p>
                        <p>状況は他人よって影響を受けます。しかし、<span className="marker-b">自分の意志は他人から影響されることのない聖域</span>です。</p>
                        <p>どうか、<span className="marker-b">正しく生きる強い意志</span>をもってください。</p>
                        <p>あなたがあなたたる理由は、<span className="f-l">どんな行動をしたか</span>、のみが規定します。</p>
                        <p>辛くなったらこの言葉に戻りましょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">07.</div>
                        <div className="l-feature__quote">
                          {data.post7.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>偉大な音楽家、ベートーヴェンの人生には大きな障害がありました。</p>
                        <p>徐々に難聴が進行し、<span className="marker-b">４０歳を迎えるころには全ろう</span>となってしまいました。</p>
                        <p>音楽家として致命的な障害。しかし彼は諦めませんでした。</p>
                        <p>彼はその後も、精力的に活動を続け、歴史的な名作を残しました。</p>
                        <p>そんな彼の功績を見れば、この言葉の重みがより一層感じられます。</p>
                        <p><span className="marker-y">自身の不幸を嘆くことなく、自暴自棄になることなく、ただ耐え忍び活動を続けました。</span></p>
                        <p>そんなベートーヴェンが歴史に残るのは当然のことだと思います。</p>
                        <p>人生はどんな状況でも諦めてはいけない。そんな真理を教えてくれる言葉です。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">08.</div>
                        <div className="l-feature__quote">
                          {data.post8.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>２０２１年は大谷翔平選手が、MLBを席巻した年です。</p>
                        <p>ベーブ・ルース以来の二刀流選手として大躍進しました。</p>
                        <p>大谷選手の活躍により、目に触れることが多くなったベーブ・ルースという人物。</p>
                        <p>彼も多くの名言を残したことで知られます。</p>
                        <p><span className="f-l">諦めないやつに勝てっこない。</span></p>
                        <p>素晴らしいですね。</p>
                        <p>人生において負けとは、<span className="marker-b">諦めた瞬間</span>に訪れます。</p>
                        <p>表面上、どんなに負けたとしても<span className="marker-b">諦めない限り勝負は続いています</span>。</p>
                        <p>この真理を知っていれば、<span className="marker-b">あなたの人生で負けはなくなります。</span></p>
                        <p>ただ、<span className="f-l">諦めずに努力すればいい</span>のです。</p>
                        <p>２０歳でも６０歳でも関係ありません。諦めなければ必ず勝てます。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">09.</div>
                        <div className="l-feature__quote">
                          {data.post9.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>忍耐は、人間に必要な資質です。</p>
                        <p>知恵者はそれを知っています。だから<span className="marker-b">忍耐することに前向きに耐えます。</span></p>
                        <p>忍耐なくして成功なし。</p>
                        <p><span className="f-l">忍耐は必要なことだと割り切っている人を知恵者</span>と言ってもいいかもしれません。</p>
                        <p>ぜひ、知恵者になってください。忍耐することを喜んでください。</p>
                        <p><span className="marker-b">その忍耐は成功に繋がります。その忍耐は成長に繋がります。</span></p>

                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">10.</div>
                        <div className="l-feature__quote">
                          {data.post10.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>中華民国籍の日本の元プロ野球選手、王貞治。</p>
                        <p>１３年連続の本塁打王に輝くなど、日本プロ野球界の伝説です。</p>
                        <p>その輝かしいキャリアの裏には、<span className="marker-b">人知れぬ苦悩と努力の日々があった</span>ことでしょう。</p>
                        <p>記録や、華々しいホームランばかりがフィーチャーされがちですが、その背後には苦悩があることを忘れてはいけません。</p>
                        <p>長年、第一線で活躍し続けるとはどんな苦労があったことでしょう。</p>
                        <p>そんな、彼の人生を象徴するような言葉です。</p>

                      </div>

                    </div>
                  </div>
                  <div className="l-feature__trunk">
                    <FeatureRelated />
                  </div>
                </div>

                <div className="l-feature__data">
                  <FeatureSide data={data} />
                </div>

              </div>

            </div>

          </div>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    post1: allContentfulBlogPost(
      filter: {id: {eq: "9c6c92ca-39c0-50c3-a2cb-c19674c3f205"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post2: allContentfulBlogPost(
      filter: {id: {eq: "52939aab-b6aa-5c6c-ba1e-81b04648de7c"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post3: allContentfulBlogPost(
      filter: {id: {eq: "4ef3397b-dc12-5789-ba2e-addd9c1b8850"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post4: allContentfulBlogPost(
      filter: {id: {eq: "bbdc575f-2baf-55ae-a80d-ba4230a0f9f9"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post5: allContentfulBlogPost(
      filter: {id: {eq: "7de8453b-2b68-525d-b58f-4b9d0b4dcac3"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post6: allContentfulBlogPost(
      filter: {id: {eq: "19afaced-8b58-5731-9616-ea16a3b7341d"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post7: allContentfulBlogPost(
      filter: {id: {eq: "2642143f-ebfe-5d17-9a09-95bc062b2dca"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post8: allContentfulBlogPost(
      filter: {id: {eq: "cd4f57a0-09f2-5d97-9542-e08c42ecdfda"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post9: allContentfulBlogPost(
      filter: {id: {eq: "a10bb370-b7cc-540d-be9d-7308c4bf1d9f"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post10: allContentfulBlogPost(
      filter: {id: {eq: "87fe18f1-ec48-5cdb-ad13-dc541aece54a"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    cate: allContentfulCategory {
      edges {
        node {
          informations {
            informations
          }
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    allCate: allContentfulCategory(
      sort: {fields: sort, order: DESC},
      limit: 20
    ) {
      edges {
        node {
          name
          slug
          id
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    tags: allContentfulTags {
      edges {
        node {
          title
          slug
          id
        }
      }
    }
  }
`

export default Home